<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

<b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="require('@/assets/images/logo/logo.jpg')"
            alt="Register V2"
          />
        </div>
      </b-col>

      <!-- Left Text-->

      <!-- /Left Text-->

      <!-- Forgot password-->
       <b-col lg="6" xl="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" xl="" class="px-xl-2 mx-auto">

              <div
                role="alert"
                v-bind:class="{ show: errors.length > 0 }"
                class="alert fade alert-danger"
              >
                <div class="alert-text">
                  {{ errors }}
                </div>
              </div>
          <b-card-text class="mb-2">
              <label>
          الرجاء إدخال الكود
          </label>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Code"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Code"
                  rules="required"
                >
                  <b-form-input
                    id="Code"
                    v-model="code"
                    :state="errors.length > 0 ? false:null"
                    name="Code"

                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
               ok
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" />
              <label> العودة إلى صفحة تسجيل الدخول</label>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
     <!-- <b-col class="div"><app-footer /></b-col> -->
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import AppFooter from "@core/layouts/components/AppFooter.vue";
import { mapState } from "vuex";
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import { VALIDATE_CODE } from "@/store/auth.module.js";
export default {
  components: {
    AppFooter,
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      code:'',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
     ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          let data = {
            email:localStorage.getItem("email"),
            code: this.code,
          };
          //console.log(data);
          this.$store
            .dispatch(VALIDATE_CODE, data)
            .then((res) => {
                //console.log("ress",res)
                  localStorage.setItem('userData', JSON.stringify(res.data.user))
                 const UserData=JSON.parse(localStorage.getItem('permissions'))
   const permissions= JSON.parse(localStorage.getItem('permissions'))

             this.$ability.update(permissions)
                this.$router.push("/");
            }).catch((error) => {
                //console.log(error)
              this.$refs.simpleRules.setErrors("error.response.data.error");
            });
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
